
import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  DistanceMatrixService,
  Marker
} from "@react-google-maps/api";
import companyLogoBlack from '../Assets/icons/companyLogoWhiteIcon.png'


const MapComponent = ({mapCenter, setAddress, setDistance}) => {
  const [map, setMap] = useState(null);
  const [clickedPosition, setClickedPosition] = useState(mapCenter);
  const [destination, setDestination] = useState({});

  const api_key = process.env.REACT_APP_GoogleMapApi;
  useEffect(()=>{setDestination(mapCenter)},[])


  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const handleMapLoad = (map) => {
    setMap(map);
  };

  const handleMapClick = async (event) => {
    setClickedPosition(event.latLng.toJSON());
    getAddressAndDistance(event.latLng.toJSON());
    setDestination(event.latLng.toJSON());
  };

  const getAddressAndDistance = async (latLng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${api_key}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error("Error fetching address and distance:", error);
    }
  };



  return (
    <div style={{ height: "400px", width: "100%" }}>


        

      <LoadScript googleMapsApiKey={api_key} libraries={["places"]}>
     

        <GoogleMap
          onLoad={handleMapLoad}
          onClick={handleMapClick}
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={15}
        >
              {clickedPosition && (
              <Marker position={clickedPosition} draggable onDragEnd={(e)=>{console.log(e.latLng.lat());}} />
            )}
              <Marker title="Monalisakumla" icon={companyLogoBlack}  className="text-black" position={{ lat: 59.1289805, lng: 15.1415457 }} />

          {destination && <DistanceMatrixService
            options={{
              destinations: [destination],
              
              origins: [{ lat: 59.1289805, lng: 15.1415457 }],
              travelMode: "DRIVING",
            }}
            callback={(response) => {
              
              setDistance(response?.rows[0]?.elements[0]?.distance?.text);
            }}
          />}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
