import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImageUrl, firebasefunctions } from "../firebaseConfig";
import DishImage from "../Assets/Images/dish.svg";
import { t } from "i18next";
import cartItemPrice from "../util/cartItmePrice";
import { httpsCallable } from "firebase/functions";

function OrderSummary() {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const orderTotal = cart.subTotal + user.deliveryCharge;
  const calculateVAT = (amount) => {
    const vat = (amount * 0.20);
    return vat;
  };
   const handlePay =()=>{
    navigate("/checkout")
   }
  useEffect(()=>{
    const oldPaymentId = localStorage.getItem("PID");
    const oldcartId = localStorage.getItem("CID");
    if(oldPaymentId && oldcartId){
      const TerminatePayment = httpsCallable(firebasefunctions, "TerminatePaymentAndDeleteCart");
      
      TerminatePayment({ oldPaymentId, oldcartId   })
        .then((response) => {
          localStorage.removeItem("PID");
          localStorage.removeItem("CID");
          localStorage.removeItem("orderNumber");
          console.log("cleared");
        })
        .catch((error) => {
          console.log("Nothing Found");
       
        });
    }

  },[])
 
  return (
    <>
      <NavBar />
      <h3 className="text-xl font-bold p-5 flex flex-row-reverse md:flex-row">
        {" "}
        Order summary
      </h3>

      <div className="p-7 flex flex-col md:flex-row  gap-3 ">
        <div className=" flex-1 border  shadow-sm p-5">
          {" "}
          <div className="flex flex-col gap-[24px]   ">
            {cart.items?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between shadow-md items-start p-3 gap-[14px]"
              >
                <div className="flex gap-10">
                  {item.downloadToken && (
                    <img
                      src={`${ImageUrl}${item.downloadToken}`}
                      alt="_food"
                      className="rounded-[18px] w-[82px] h-[83px]"
                    />
                  )}
                  {!item.downloadToken && (
                    <div className=" shrink-0">
                      <img
                        src={DishImage}
                        alt="_food"
                        height={50}
                        width={50}
                        className="rounded-[18px] shrink-0"
                      />
                    </div>
                  )}

                  <div className="flex flex-col gap-[14px]">
                    <p className="text-black font-epilogue font-bold text-base  leading-normal">
                      {item.name}
                    </p>
                    <p className="text-[#FE724C] font-epilogue text-base font-medium leading-normal">
                      Kr {cartItemPrice(item)}
                    </p>
                    {item.options &&
                      item.options.map((option, index) => (
                        <div key={"option_" + index}>
                          <span className=" font-semibold">
                            {option.group_name}:
                          </span>
                          <div className="flex flex-col px-3">
                            {option.selectedOptions.map(
                              (selectedOption, index) => (
                                <span key={"so" + index}>
                                  {selectedOption.quantity} x{" "}
                                  {selectedOption.name}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="hidden flex-col justify-between gap-2 pr-[20px] md:flex">
                  {/* Quantity starts */}
                  <div className="flex gap-2  justify-center items-center ">
                    <span className="text-xs  text-gray-400">Qyt: </span>{" "}
                    <span className="text-[#5B5B5E] font-epilogue text-base font-normal leading-6">
                      {item.quantity}
                    </span>{" "}
                  </div>
                  {/* Quantity ends */}
                </div>
              </div>
            ))}
          </div>{" "}
        </div>
        <div className="p-6 border  shadow max-h-96 md:min-w-[300px]">
          <div className="flex justify-between items-center py-[16px] border-b border-[#F1F2F3]">
            <p className="text-[#14171A] font-epilogue text-base font-normal leading-normal">
              Subtotal
            </p>
            <p className="text-[#14171A] text-right font-epilogue text-base font-normal leading-normal">
              {cart.subTotal.toFixed(2)} <span className="text-xs"> kr</span>
            </p>
          </div>
          <div className="flex justify-between  items-center py-[16px] border-b border-[#F1F2F3]">
            <p className="text-[#14171A] font-epilogue text-xs font-normal leading-normal">
              {t("inclusive_VAT")}
            </p>
            <p className="text-[#14171A] text-right font-epilogue text-sm font-normal leading-normal">
              {cart.VAT.toFixed(2)} <span className="text-xs"> kr</span>
            </p>
          </div>
          <div>
          
            {cart.order_type === "delivery" && (
              <>
                <div className="flex justify-between items-center py-[16px] border-b border-[#F1F2F3]">
              <p className="text-[#14171A] font-epilogue text-base font-normal leading-normal">
                Delivery Fee
              </p>
              <p className="text-[#14171A] text-right font-epilogue text-base font-normal leading-normal">
                {user.deliveryCharge.toFixed(2)}{" "}
                <span className="text-xs"> kr</span>
              </p>
            </div>
            <div className="flex justify-between  items-center py-[16px] border-b border-[#F1F2F3]">
                <p className="text-[#14171A] font-epilogue text-xs font-normal leading-normal">
                  Delivery Fee Tax (25% inclusive)
                </p>
                <p className="text-[#14171A] text-right font-epilogue text-sm font-normal leading-normal">
                  {calculateVAT(user.deliveryCharge).toFixed(2)}{" "}
                  <span className="text-xs"> kr</span>
                </p>
              </div>
              </>
           
            )}
          </div>
          <div className=" w-full border-t-2 p-3 flex justify-between"> <span>Total:</span> <span>{orderTotal} kr</span></div>
          <div className="text-center">
            <button
              type="button"
              onClick={handlePay}
              className="text-md font-semibold rounded-xl text-white bg-[#eb6700] px-7 py-3 hover:bg-[#f06a31]"
            >
              Pay
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummary;
