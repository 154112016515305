import React from "react";
import NavBar from "../Components/NavBar";

const TermsAndConditions = () => {
  return (
    <>
      <NavBar />
      <h1 className="text-center font-extrabold text-2xl">
        {" "}
        Terms & Condition
      </h1>
      <div className="p-5">
        <b >
          1. Introduction
        </b>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          These Terms of Use apply to <b _istranslated="1">monalisakumla.com</b>
          . Please read all the terms and conditions carefully before
          proceeding. By accepting these terms and conditions,{" "}
          <b _istranslated="1">
            you agree to enter into a legally binding agreement
          </b>{" "}
          with Restaurang och Pizzeria Mona Lisa i Kumla AB with organization
          number 559208-8065.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          If you do not accept our terms and conditions, consent should not be
          given, the website and its services will not be able to be used in
          such a situation.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          If you wish to terminate an <b _istranslated="1">agreement</b>, this
          can be done in the following ways:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Please contact us via the following e-mail address:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          monalisa_ikumla@hotmail.com
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          The following terms and conditions are subject to{" "}
          <b _istranslated="1">change</b>. Prior to any changes taking effect,
          users will be notified as follows:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Changes will be announced on the home page
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            2. Purpose
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          The purpose of the website is e-commerce as follows:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Sale of food to consumers
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            3. Property Rights to the Website
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          The website <b _istranslated="1">is owned</b> and{" "}
          <b _istranslated="1">managed</b> by{" "}
          <b _istranslated="1">Restaurang och Pizzeria Mona Lisa i Kumla AB</b>{" "}
          in its capacity as a legal entity with its registered office in
          Örebro.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            4. Terms of Use
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          {" "}
          <b _istranslated="1">a)</b> Users shall not violate the rules of the
          website
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          {" "}
          <b _istranslated="1">(b)</b> Users shall follow step-by-step
          instructions
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          {" "}
          <b >c)</b> Users shall not counterfeit purchases from
          the Website
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            5. Intellectual Property
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          The copyrighted material available on the website{" "}
          <b _istranslated="1">
            may not be used without permission from the author
          </b>
          . Users are not permitted to copy, distribute, commercially exploit,
          reproduce or otherwise benefit from such material.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            6. Payment
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          The User's purchases on the Website are managed and must be paid as
          below.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          We are responsible for all payment processing and billing is as
          follows:{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Payment can either be made via invoice, card reception (in premises)
          or Swish.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            7. Privacy Policy
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          {" "}
          <b>a)</b> the website undertakes to comply with the{" "}
          <b >General Data Protection Regulation </b>(GDPR),
          the purpose of which is, among other things, to protect the
          fundamental rights and freedoms of the individual.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            b) Personal data that will/may be processed
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          By accepting these Terms of Use, you consent to the processing of
          personal data by the user as follows:{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Users' name, address, phone number, and email
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Sensitive personal data is not allowed to be collected and processed.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            c) How the personal data will be processed
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div>
          The personal data will be processed for the following purposes:{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          The personal data will be used for the collection and home delivery of
          food{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            (d) Third parties
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div>
          The users' social security numbers will not be disclosed to third
          parties, unless it is required due to mandatory regulations, orders
          from the courts or to be able to complete the consumer's purchase.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            e) Period for which the personal data will be stored
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          Personal data may only be stored for as long as it is needed for
          purposes and for a maximum of seven days{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            f) Users' rights
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            Right to register extracts
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          After giving consent, users have the right to receive an extract from
          the register with confirmation of whether the personal data is being
          processed. If the personal data is processed, users have the right to
          access it.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Registry extracts are requested from us at Restaurant and Pizzeria
          Mona Lisa i Kumla AB.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          The register extract is provided in written form, either digitally or
          by post.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            Right to information and right to rectification
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div>
          After giving consent, users have the right to contact Restaurang och
          Pizzeria Mona Lisa i Kumla AB to find out what personal data is stored
          and have incorrect information changed. If any information is missing,
          users have the right to supplement it.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            Right to restriction of processing
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          In some cases, a person whose personal data is involved may demand
          that the processing of the personal data be restricted. This is the
          case, for example, when the person in question has already requested
          correction due to incorrect information. During this period, the
          processing of the incorrect data may also be restricted.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            Data portability
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          In some cases, a person who has disclosed their personal data has the
          right to have it moved to another person, such as a media service. The
          person who first received the personal data must then facilitate such
          a transfer.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            Right to erasure
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          Users have a right to have their personal data erased if desired.
          There are exceptions, so this right is restricted, but in the
          following cases, the data must be deleted:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          - If the data is no longer needed for the purposes for which it was
          collected,{" "}
        </div>{" "}
        <div >
          - If the consent has been withdrawn,
        </div>{" "}
        <div >
          - If the personal data is used for direct marketing purposes and the
          user objects to it,
        </div>{" "}
        <div >
          - If there is no legitimate reason that outweighs the individual's
          interest in deleting the information,
        </div>{" "}
        <div >
          - If the personal data has been unlawfully processed;{" "}
        </div>{" "}
        <div>
          - If deletion is required to comply with a legal obligation,
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          If a user wishes to have their personal data deleted, they should
          contact us either by phone or e-mail.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            8. User's Breach of Agreement
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          A user who violates these Terms of Use will be dealt with as follows:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          The user is barred from ordering food from the restaurant
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          The user can be sued in the event of a serious breach of contract
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            9. Disclaimer &amp; Limitation of Liability
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          Restaurang och Pizzeria Mona Lisa i Kumla AB, which owns the website,
          monalisakumla.com, disclaims the following legal liability:
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          To the extent that they do not conflict with the law, Restaurang och
          Pizzeria Mona Lisa i Kumla AB shall not be held liable for loss of
          data as well as punitive damages relating to material on the website.
          .{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            10. Jurisdiction
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div>
          The website, monalisakumla.com, is operated from Sweden and is
          governed by Swedish law.{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b >
            11. Contact
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            {" "}
            <br />{" "}
          </b>{" "}
        </div>{" "}
        <div >
          For questions regarding the website and its content, we can be reached
          by e-mail address monalisa_ikumla@hotmail.com or by phone 019-52 15 02
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Telephone hours:{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div >
          Tuesday - Tueday: 11:00 - 21:00
        </div>{" "}
        <div >
          Fri: 11:00 - 22:00
        </div>{" "}
        <div >
          Saturday: 12:00 - 21:00
        </div>{" "}
        <div >
          Sunday: 11:00 - 21:00{" "}
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
