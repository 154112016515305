import React from "react";
import NavBar from "../Components/NavBar";

const PrivacyPolicy = () => {
  return (
    <>
    <NavBar/>
    <h1 className="text-center font-extrabold text-2xl"> Privacy Policy</h1>
      <div className="content">
        <div>
          <span>
            <b>Syfte</b>
          </span>
        </div>
        <div>
          <span>
            <b>
             
              <br />
            </b>
          </span>
        </div>
        <div>
          <span>
            Syftet med vår Integritetspolicy är att berätta hur vi behandlar
            dina personuppgifter samt vad vi använder dem till. Vi beskriver
            också dina rättigheter och hur du kan göra dem gällande.
          </span>
          <font color="#111111" face="Quicksand, sans-serif">
            <span>
              {" "}
              <br />{" "}
            </span>{" "}
          </font>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <b>Vad är en personuppgift?</b>{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <b>
              {" "}
              <br />{" "}
            </b>{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            En personuppgift är information som är möjlig att knyta till dig,
            exempelvis ditt namn, din adress osv.&nbsp;
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <b>Anledningen till varför vi behandlar dina personuppgifter.</b>{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          Vi behandlar era personuppgifter för att kunna fullfölja våra
          förpliktelser mot dig, t.ex. måste vi behandla dina personuppgifter
          för att bekrefta en order, få maten hemlevererat till dig osv. Vi
          strävar alltid efter att inte fråga om fler personuppgifter än de
          nödvändiga.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <b>
            I vilka fall behandlas personuppgifter och vilken laglig grund finns
            för detta?
          </b>{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Vi behandlar personuppgifter antingen när vi har laglig grund för
            det eller när ett samtycke har getts.
          </span>{" "}
          <br />{" "}
          <span>
            Laglig grund:&nbsp;Fullgörande av köpeavtalet. Vi samlar in de
            personuppgifter som krävs för att vi ska kunna fullgöra våra
            åtaganden enligt köpeavtalet.
          </span>{" "}
          <br /> <br />{" "}
          <span>
            Samtycke: Du ger oss ditt samtycke till att vi får behandla en eller
            flera personuppgifter. Ett exempel på detta är t.ex. om du beställer
            för hemkörning.&nbsp;
          </span>{" "}
          <br /> <br />{" "}
          <span>
            Intresseavvägning: Här görs en avvägning vilket kan resultera i att
            vi bedömer oss ha ett intresse att få behandla personuppgifter.
          </span>{" "}
          <br /> <br />{" "}
          <span>
            Exempel på laglig grund för behandling av personuppgifter:
          </span>{" "}
          <br />{" "}
          <ul>
            {" "}
            <li>
              Kunna hantera ditt köp på vår webbplats (inkl. skicka din
              beställning, hantera returer, samt kunna skicka meddelande/ringa
              gällande leveransstatus osv.)
              <br /> <em>Behandlingen baseras på: avtal</em>{" "}
            </li>{" "}
            <li>
              Kunna hantera förfrågningar från dig via vår kundservice.
              <br /> <em>Behandlingen baseras på: avtal</em>{" "}
            </li>{" "}
            <li>
              Kunna skicka ut din beställning till dig.
              <br /> <em>Behandlingen baseras på: samtycke</em>{" "}
            </li>{" "}
          </ul>{" "}
          <div>
            {" "}
            <br />{" "}
          </div>{" "}
          <div>
            {" "}
            <b>Vilka personuppgifter behanldar vi?</b>{" "}
          </div>{" "}
          <div>
            {" "}
            <b>
              {" "}
              <br />{" "}
            </b>{" "}
          </div>{" "}
          <div>
            {" "}
            <span>
              Här följer några exempel på personuppgifter som vi behandlar:
            </span>{" "}
            <br />{" "}
            <ul>
              {" "}
              <li>* Namn</li>{" "}
              <li>
                * Kontaktuppgifter (t.ex. adress, e-post och telefonnummer)
              </li>{" "}
              <li>
                * Köpinformation (t.ex. vilken vara som har beställts eller om
                varan ska levereras till en annan adress)
              </li>{" "}
              <li>
                * Uppgifter som du registrerade självmant och frivilligt uppger
                (allergier osv.)
              </li>{" "}
              <li>* Betalningshistorik</li> <li>* Betalningsinformation</li>{" "}
            </ul>{" "}
          </div>{" "}
          <ul>
            {" "}
            <li> </li>{" "}
          </ul>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Dina rättigheter.</span> <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            I enlighet med GDPR (EU:s dataskyddsförordning) så har du ett antal
            rättigheter avseende behandlingen av dina personuppgifter. Nedan har
            vi kort beskrivit vilka rättigheter du har. Du kan alltid vända dig
            till oss för att utöva dina rättigheter.
          </span>{" "}
          <br /> <br />{" "}
          <ul>
            {" "}
            <li>
              Rätt till registerutdrag. Detta innebär att du får tillgång till
              de personuppgifter som vi behandlar om dig.
            </li>{" "}
            <li>
              Rätt till rättelse. Detta innebär att du kan begära att dina
              personuppgifter skall rättas i de fall uppgifterna är felaktiga.
            </li>{" "}
            <li>
              Rätt att dra tillbaka medgivande. Detta innebär att du alltid har
              rätt att dra tillbaka ett medgivande du gett till oss. Ett exempel
              på detta kan vara att du inte längre vill få fler nyhetsbrev
              skickade till dig.
            </li>{" "}
            <li>
              Rätt till radering. Detta innebär att du har rätt att begära
              radering av de personuppgifter som vi behandlar om dig. Tänk dock
              på att vi kan ha rätt att neka din begäran ifall det finns legala
              skyldigheter som hindrar oss från att radera vissa
              personuppgifter.
            </li>{" "}
            <li>
              Rätt till begränsning av behandling.&nbsp;Detta innebär att du har
              rätt att begära att behandlingen av dina personuppgifter
              begränsas. Exempelvis i det fall där du bestrider uppgifternas
              korrekthet.
            </li>{" "}
            <li>
              Rätt att invända mot behandling. Detta innebär att du har rätt att
              invända mot behandling som grundar sig på en intresseavvägning.
              Det kan dock finnas undantag för denna rättighet.
            </li>{" "}
            <li>
              Du har rätt att invända mot att dina personuppgifter behandlas för
              marknadsförings-ändamål.
            </li>{" "}
            <li>
              Du har rätt att inge klagomål. Detta innebär att du har rätt att
              lämna klagomål till tillsynsmyndigheten Datainspektionen (
              <a
                href="http://www.datainspektionen.se/"
                target="_blank"
                rel="noopener"
              >
                datainspektionen.se
              </a>
              ).
            </li>{" "}
            <li>
              Rätt till dataportabilitet.&nbsp;Detta innebär att du har rätt att
              få ut dina personuppgifter i ett strukturerat, allmänt använt och
              maskinläsbart format för att sedan kunna överföra dessa till en
              annan personuppgiftsansvarig. Det kan ibland finnas tekniska
              begräsningar kring detta.
            </li>{" "}
          </ul>{" "}
          <div>
            {" "}
            <font color="#111111" face="Quicksand, sans-serif">
              {" "}
              <span>
                {" "}
                <br />{" "}
              </span>{" "}
            </font>{" "}
          </div>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Hur får vi tillgång till dina personuppgifter?
          </span> <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Vi får tillgång till dina personuppgifter på följande sätt:
          </span>{" "}
          <br />{" "}
          <ul>
            {" "}
            <li>Uppgifter som du ger oss direkt</li>{" "}
            <li>Uppgifter som registreras när du besöker vår hemsida</li>{" "}
            <li>Uppgifter som vi får från offentliga register</li>{" "}
            <li>Uppgifter som vi får när du beställer för hemkörning</li>{" "}
            <li>
              Uppgifter som vi får när du kontaktar oss, söker anställning hos
              oss, besöker oss eller på annat sätt tar kontakt med oss
            </li>{" "}
          </ul>{" "}
          <div>
            {" "}
            <font color="#111111" face="Quicksand, sans-serif">
              {" "}
              <span>
                {" "}
                <br />{" "}
              </span>{" "}
            </font>{" "}
          </div>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Så här länge&nbsp; sparas dina personuppgifter.
          </span> <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Vi sparar aldrig dina personuppgifter längre än vi behöver för att
            uppfylla våra förpliktelser gentemot dig samt den tid som kan krävas
            för att vi ska uppfylla lagar och regler. T.ex. bokföringslagen.
            Dessutom sparas endast personuppgifter i de fall som vi har laglig
            grund eller där ett samtycke uttryckligen har getts.
          </span>{" "}
          <br />{" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Exempel på lagringsperioder:</span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Vid köp på webbplatsen behåller vi uppgifterna i&nbsp;
          </span>{" "}
          <span>
            sju år plus innevarande år, detta för att uppfylla bokföringslagen
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Vid kundcervice ärenden&nbsp;</span>{" "}
          <span>behåller vi uppgifterna i&nbsp;</span>{" "}
          <span>7 dagar efter att kundservice-ärendet har avslutats</span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Vid rekryteringsprocesser&nbsp;</span>{" "}
          <span>
            behåller vi uppgifterna tills rekryteringsprocessen är avslutad
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Vid marknadsföringsaktiviteter</span>{" "}
          <span>
            &nbsp;behåller vi uppgifterna tills du drar tillbaka ditt samtycke.
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>När lämnar vi ut dina personuppgifter?</span> <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            För att kunna erbjuda våra tjänster kan det vara nödvändigt för oss
            att dela dina personuppgifter med andra företag. I dessa fall har vi
            upprättat personuppgiftsbiträdesavtal, detta innebär att det dessa
            företag behandlar uppgifterna för vår räkning och i enligt med våra
            instruktioner. Exempel på personuppgiftsbiträden kan vara
            betallösningar, IT-tjänster och marknadsföring.
          </span>{" "}
          <br />{" "}
          <span>
            Vi kan också komma att dela personuppgifter med företag som är
            självständigt personuppgiftsansvariga. Detta innebär att inte vi
            styr kring hur informationen ska behandlas av företaget. Exempel på
            självständigt personuppgiftsansvariga kan vara statliga myndigheter
            (t.ex. skatteverket), fraktbolag och bolag som jobbar med olika
            betallösningar.
          </span>{" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Personuppgiftsansvarig.</span>{" "}
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          Restaurang och Pizzeria Mona Lisa i Kumla AB (org. nummer 55908-8065),
          Trädgårdsgatan 3, 692 31 Kumla är personuppgiftsansvarig, detta
          innebär bland annat att vi är ansvariga för hur dina personuppgifter
          behandlas samt att dina rättigheter tas tillvara.
        </div>{" "}
        <div>
          {" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Var behandlar vi dina personuppgifter?</span> <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Så långt det är möjligt försöker vi alltid se till att dina
            personuppgifter behandlas inom EU/EES
          </span>{" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Automatiserade beslut?</span>{" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Vi fattar inga automatiserade beslut om dig.</span>{" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>Vår E-post policy.</span>{" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            På detta sätt efterföljer vi GDPR gällande ostrukturerade data i
            e-post. Vi efterlever denna policy oavsett om det handlar om e-post
            som skickas internt eller externt.
          </span>{" "}
          <br />{" "}
          <span>
            När vi mottagit och läst e-posten, bedömer vi alltid om uppgifterna
            ska bevaras och var det i så fall ska ske för att uppfylla de krav
            som gäller för just dessa uppgifter.
          </span>{" "}
          <br />{" "}
          <span>
            Vi skickar aldrig känsliga personuppgifter i oskyddad e-post. Vi
            behandlar eller lagrar aldrig personuppgifter som är känsliga eller
            sekretessbelagda i vår e-post.
          </span>{" "}
          <br />{" "}
          <span>
            Vi informerar löpande alla i vår organisation om reglerna och
            rutinerna kring hur vi behandlar personuppgifter.
          </span>{" "}
          <br />{" "}
          <span>
            Du kan läsa hur vi behandlar personuppgifter i vår
            integritetspolicy.
          </span>{" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <div>
            {" "}
            <span>Har du frågor?</span>{" "}
            <span>
              {" "}
              <br />{" "}
            </span>{" "}
          </div>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            Det är alltid enkelt att komma i kontakt med oss. Om du vill du veta
            mer om vår integritetspolicy, eller nyttja dina rättigheter kring
            dina personuppgifter, så kontaktar du oss enklast via mail:
            monalisa_ikumla@hotmail.com
          </span>{" "}
          <br />{" "}
          <span>Integritetspolicyn är senast uppdaterad: 2022-01-12.</span>{" "}
          <br />{" "}
          <span>
            Du finner alltid den senaste versionen av vår integritetspolicy här
            på vår webbplats. I det fall vi gör uppdateringar som är av
            avgörande art, så kommer du att få information kring detta på vår
            webbplats och/eller via mail.
          </span>{" "}
          <br />{" "}
        </div>{" "}
        <div>
          {" "}
          <span>
            {" "}
            <br />{" "}
          </span>{" "}
        </div>
      </div>{" "}
    </>
  );
};

export default PrivacyPolicy;
